import {
    FaFacebook,
    FaTwitter,
    FaYoutube,
    //FaInstagram,
    //FaTelegram,
    FaReddit,
    FaTiktok,
} from "react-icons/fa"

export const socialMediaLinks = [
    {
        icon: FaFacebook,
        to: "https://facebook.com/classicfit.org",
        ariaLabel: "Facebook",
    },
    {
        icon: FaTwitter,
        to: "https://twitter.com/ClassicFit_org",
        ariaLabel: "Twitter",
    },
    /*{
      icon: FaInstagram,
      to: "https://instagram.com/classicfit_org", //TODO: account disabled to enable again with the help of support
      ariaLabel: "Instagram",
    },*/
    {
        icon: FaYoutube,
        to: "https://www.youtube.com/@classicfit_org/featured",
        ariaLabel: "Youtube",
    },
    {
        icon: FaTiktok,
        to: "https://www.tiktok.com/@classicfit_org",
        ariaLabel: "Tiktok",
    },
    /*{
      icon: FaTelegram,
      to: "https://web.telegram.org/k/", // TODO: add telegram ??
      ariaLabel: "Telegram",
    },*/
    {
        icon: FaReddit,
        to: "https://www.reddit.com/r/classicfit_org/",
        ariaLabel: "Reddit",
    },
]